import { stores } from '@stores/.';
import { AuthorizationScheme } from '@stores/security';
import ErrorLogger, { RequestConfig } from '@utils/errorLogger';
import Axios, { AxiosInstance } from 'axios';

export const refreshTokenInterceptor = (axiosInstance: AxiosInstance) => {
  // Generic response interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,

    (error) => {
      const request = error.config;

      // sign out user if failed to get token from refreshToken
      if (
        error &&
        error.response &&
        (error.response.status === 400 || error.response.status === 500) &&
        request.url.includes('refreshToken')
      ) {
        stores.userStore.signOutUser();
        return Promise.reject(error);
      }

      // retry request once, with new idToken if first time it's unauthorized
      if (error?.response?.status === 401 && !request._retry) {
        request._retry = true;
        // get and set the new token
        return stores.userStore.setAndRefreshToken(stores.userStore.token).then(() => {
          request.headers.authorization = `Bearer ${stores.userStore.token}`;
          return Axios(request);
        });
      }

      ErrorLogger.logApi(error);

      return Promise.reject(error);
    },
  );
};

/**
 * Use when we need to ask additional authorization from a user to perform
 * privileged actions; eg inviting users, or updating MFA phone number
 */
export const mfaInterceptor = (axiosInstance: AxiosInstance) => {
  /* Security token expiry interceptor:
   *
   * If authorizationToken expires by the time user submits security
   * response(password/otp), backend returns response with new token. This
   * interceptor replaces the token in the earlier request and send a new
   * request to backend.
   * */
  axiosInstance.interceptors.response.use((response) => {
    const request = response.config;
    let data;

    try {
      data = JSON.parse(request?.data || '{}');
    } catch (e) {
      return response;
    }

    if (response?.data?.scheme && data.scheme) {
      data = {
        ...(data || {}),
        authorizationToken: response.data.authorizationToken,
        scheme: response.data.scheme,
      };

      request.data = data;

      return axiosInstance(request);
    }

    return response;
  });

  /* Security interceptor:
   *
   * When a request requires user to verify identity, backend will respond with
   * scheme and authorizationToken. This interceptor intercepts the response
   * and shows user a popup to verify the identity. Once the user enter the
   * required verification and submits, earlier request is called with
   * authorizationToken, scheme and code/password in the request body. This
   * also handle the error in user response for verification.
   * */
  axiosInstance.interceptors.response.use(
    async (response) => {
      // check if response has scheme to show the verification popup
      if (response?.data?.scheme) {
        const request = response.config;
        const userResponse = await stores.securityStore.getResponse(response.data.scheme);
        let data = JSON.parse(request.data || '{}');
        // add token, scheme and user response to the request body
        data = {
          ...data,
          [response.data.scheme === AuthorizationScheme.Password ? 'password' : 'code']:
            userResponse,
          authorizationToken: response.data.authorizationToken,
          scheme: response.data.scheme,
        };
        request.data = data;
        return axiosInstance(request).then((resp) => {
          stores.securityStore.close();
          return resp;
        });
      }
      return response;
    },
    async (error) => {
      const request = error.config;

      let data;

      try {
        data = JSON.parse(request?.data || '{}');
      } catch (e) {
        return Promise.reject(request?.data);
      }

      const { status, data: responseData } = error.response || { status: 0, data: undefined };
      const { message } = responseData || { message: 'An error occurred' };
      const invalidPasswordAttempt = status === 403 && !!data.scheme;
      const invalidOtpAttempt = status === 400 && data.scheme === AuthorizationScheme.SmsOtp;

      if (invalidPasswordAttempt || invalidOtpAttempt) {
        const userResponse = await stores.securityStore.handleError(message);
        data = {
          ...data,
          [data.scheme === AuthorizationScheme.Password ? 'password' : 'code']: userResponse,
        };
        request.data = data;
        return axiosInstance(request).then((resp) => {
          stores.securityStore.close();
          return resp;
        });
      }

      return Promise.reject(error);
    },
  );
};

export const responseTimeInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    (config as RequestConfig).startTime = new Date();
    (config as RequestConfig).startVisibility = document.visibilityState;
    return config;
  });
};

export const bugsnagInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      ErrorLogger.logApi(error);
      return Promise.reject(error);
    },
  );
};
