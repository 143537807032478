import dayjs from 'dayjs';
import { ISourceStore } from '@stores/source';
import { IDestinationStore } from '@stores/destination';
import { RetlConnectionStore } from '@stores/retlConnections/retlConnection';
import { ApiResponse } from '@components/sources/sourceResponse';

export enum TabValue {
  LATEST = 'latest',
  PAST = 'past',
}

export enum SyncConfigStatus {
  STARTING = 'starting',
  SYNCING = 'in progress',
  STOPPING = 'stopping',
  PAUSED = 'paused',
  AVAILABLE_TO_SYNC = 'available_to_sync',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  ABORTED = 'aborted',
  IDLE = 'idle',
}

export enum SyncType {
  INCREMENTAL = 'incremental',
  FULL = 'full',
}

export type DateRange = { from: dayjs.Dayjs; to: dayjs.Dayjs };

export interface ILatestSyncStore {
  loadingSyncData: boolean;
  loadingSyncTrend: boolean;
  latestSync: SyncData | null;
  syncConfigStatus: SyncConfigStatus;
  isSetupCompleted: boolean;
  syncTrend: SyncTrend | null;
  errorInSyncTrend: boolean;
  isEligibleForGraph: boolean;
  isGraphAvailable: boolean;
  load: () => void;
  stopPolling: () => void;
  startSync: (syncType: SyncType) => void;
  stopSync: () => void;
  refreshSyncTrend: () => void;
  checkConnectionStatus: () => void;
  reset: () => void;
}

export interface IPastSyncsStore {
  loading: boolean;
  dateRange: DateRange;
  pageNumber: number;
  totalRuns: number;
  pastSyncs: SyncData[];
  onDateRangeChange: (dateRange: DateRange) => void;
  onPageNumberChange: (pageNumber: number) => void;
  load: () => void;
  reset: () => void;
}

export interface IRetlSyncsStore {
  source: ISourceStore | undefined;
  destination: IDestinationStore | undefined;
  connection: RetlConnectionStore | undefined;
  syncMode: string;
  tab: TabValue;
  latestSyncStore: ILatestSyncStore;
  pastSyncsStore: IPastSyncsStore;
  selectedSync: SyncData | null;
  sampleErrorsStore: ISampleErrorsStore | null;
  setTab: (tab: TabValue) => void;
  setConnectionDetails: (sourceId: string, destinationId: string) => void;
  setSampleErrorStore: (sync: SyncData, type: ErrorType) => void;
  resetSampleErrorStore: () => void;
  reset: () => void;
}

export interface SyncData extends Omit<IJobRunReport, 'status' | 'started_at' | 'finished_at'> {
  status:
    | SyncConfigStatus.SUCCEEDED
    | SyncConfigStatus.FAILED
    | SyncConfigStatus.ABORTED
    | SyncConfigStatus.SYNCING;
  duration: string;
  started_at: dayjs.Dayjs;
  finished_at: dayjs.Dayjs;
}

export interface RawSyncTrend {
  timestamps: number[];
  states: {
    succeeded: number[];
    retries: {
      '429': number[];
      '5xx': number[];
    };
    aborted: number[];
    dropped: number[];
  };
}

export enum GraphTabValue {
  SYNCED = 'synced',
  RETRYING = 'retrying',
}

export interface SyncTrendTabData {
  chartData: {
    bucket: number;
    charts: (number | null)[];
  }[];
  labels: string[];
  stepSize: number;
}

export interface SyncTrend {
  [GraphTabValue.SYNCED]: SyncTrendTabData;
  [GraphTabValue.RETRYING]: SyncTrendTabData;
}

export enum ErrorType {
  INVALID = 'sources',
  FAILED = 'server',
  ABORTED = 'aborted',
}

export type InvalidRow = Record<string, unknown>;

export type SampleError = {
  count: number;
  statusCode: number;
  reportedBy: string;
  reportedAt: number;
  state?: string;
};

export interface SampleErrorsConfig {
  errorType: ErrorType;
  runId: string;
  startedAt: dayjs.Dayjs;
  sourceId: string;
}

export interface ISampleErrorsStore {
  loading: boolean;
  config: SampleErrorsConfig;
  sampleErrors: SampleError[];
  fetchSyncErrors: () => void;
}

export enum JobRunResult {
  Succeeded = 'succeeded',
  Failed = 'failed',
  Running = 'running',
}

export interface IJobRunReport {
  run_id: string;
  started_at: string;
  finished_at: string;
  snapshot_finished_at?: string;
  trigger: 'manual' | 'scheduled';
  sync_type: 'full' | 'incremental';
  metrics: {
    total: number;
    invalid: {
      total: number;
      duplicates: number;
      nulls: number;
    };
    changed: {
      total: number; // for file-based: if we receive -1 from backend means query timed out cannot fetch the data
      inserts: number;
      updates: number;
      deletes: number;
    };
    successful: {
      total: number;
      inserts: number;
      updates: number;
      deletes: number;
    };
    failed: {
      total: number;
    };
    dropped?: {
      total: number;
    };
  };
  file_stats?: {
    total_count_status: 'pending' | 'completed' | 'failed' | 'timeout';
    total_rows: number;
    total_files: number;
    changed_files: number;
    rows_processed: number;
    breakup: FileInfo[];
  };
  error?: string;
  state: string; // a descriptive text for the user to see the job run updates
  status: JobRunResult;
}

export interface FileInfo {
  total_count_status: 'pending' | 'completed' | 'failed' | 'timeout';
  file_name: string;
  status: string;
  total_rows?: number;
  rows_processed?: number;
  rows_succeeded?: number;
  rows_failed?: number;
  rows_dropped?: number;
}

export type JobRunReportsResponse = ApiResponse<{ runs: IJobRunReport[]; total_runs: number }>;
