import { stores } from '@stores/index';
import { RetlSubcategory, SourceConfig } from './source/types';
import { WarehouseSourceOrigin } from './types';

export const getPermissions = (
  resources: { id: string; permissions?: { id: string; isLocked: boolean } }[],
) =>
  resources.map((x) => ({
    id: x.id,
    permissionId: x.permissions?.id,
    locked: x.permissions?.isLocked,
  }));

export const loadStores = async () => {
  const {
    sourcesListStore,
    destinationsListStore,
    transformationsListStore,
    retlSourceListStore,
    retlConnectionListStore,
    credentialStore,
    featuresStore,
  } = stores;

  const enableCredentialStore = featuresStore.has('CREDENTIALS');

  // No new API should be added here
  const apiCalls = [
    retlSourceListStore.getRetlSources(),
    retlConnectionListStore.getRetlConnections(),
    sourcesListStore.fetchSources(),
    destinationsListStore.getDestinations(),
    transformationsListStore.getTransformations(),
    ...(enableCredentialStore ? [credentialStore.getCredentials()] : []),
  ];
  await Promise.all(apiCalls);
};

export const isStoresLoaded = () => {
  const {
    workspaceStore,
    sourcesListStore,
    destinationsListStore,
    sourceDefinitionsListStore,
    destinationDefsListStore,
    transformationsListStore,
    featuresStore,
    retlSourceListStore,
    retlConnectionListStore,
  } = stores;

  return (
    sourceDefinitionsListStore.sourceDefinitions.length > 0 &&
    destinationDefsListStore.destinationDefs.length > 0 &&
    sourcesListStore.firstLoad &&
    destinationsListStore.firstLoad &&
    transformationsListStore.firstLoad &&
    workspaceStore.firstLoad &&
    featuresStore.firstLoad &&
    retlSourceListStore.firstLoad &&
    retlConnectionListStore.firstLoad
  );
};

export const getOrigin = (config: SourceConfig) => {
  let { origin } = config;
  const { accountId, rudderAccountId } = config;

  if (!origin) {
    if (accountId || rudderAccountId) {
      origin = WarehouseSourceOrigin.TABLE;
    } else {
      origin = WarehouseSourceOrigin.MODEL;
    }
  }

  return origin;
};

export const coerceRetlSubcategory = (category?: WarehouseSourceOrigin): RetlSubcategory => {
  switch (category) {
    case WarehouseSourceOrigin.PROFILES_AUDIENCE:
    case WarehouseSourceOrigin.COHORT_AUDIENCE:
    case WarehouseSourceOrigin.AUDIENCE:
      return 'audience' as const;
    case WarehouseSourceOrigin.PROFILES_TABLE:
    case WarehouseSourceOrigin.TABLE:
      return 'table' as const;
    case WarehouseSourceOrigin.MODEL:
      return 'model' as const;
    default:
      return 'table' as const;
  }
};
