import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { Flex } from '@ui-library/flex';
import classes from './loader.module.scss';
import loader from '@svg/animated-logo-mark.svg';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import theme from '@css/theme';
import { BaseText } from '@ui-library/typography';

type Props =
  | {
      type: 'spin';
      size?: 16 | 24 | 36 | 64;
      loading?: boolean;
    }
  | {
      type: 'screen';
      loading: boolean;
    };

const LOADING_PHRASES = [
  'Loading app',
  'Analyzing bits',
  'Mining nuggets',
  'Reticulating splines',
  'Crunching numbers',
  'Synchromeshing gears',
];

export const Loader: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const [loadingPhraseIndex, setLoadingPhraseIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initialDelay = 2250;
    const intervalDelay = 2000;

    const timeout = setTimeout(() => {
      const interval = setInterval(() => {
        setLoadingPhraseIndex((prevIndex) => (prevIndex + 1) % LOADING_PHRASES.length);
      }, intervalDelay);

      return () => clearInterval(interval);
    }, initialDelay);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (textRef.current) {
      // 50px is spinner + margin + ellipsis
      setContainerWidth(textRef.current.offsetWidth + 50);
    }
  }, [loadingPhraseIndex]);

  if (props.type === 'spin') {
    const { size = 24, children, loading = true } = props;
    return (
      <Spin
        spinning={loading}
        indicator={
          <FontAwesomeIcon
            style={{ fontSize: size }}
            icon={regular('circle-notch')}
            className="fa-spin"
            color={theme.token.colorPrimary}
          />
        }
      >
        {children}
      </Spin>
    );
  }

  if (!props.loading) {
    return null;
  }

  return (
    <Flex centered className={classes.loader} data-testid="loader">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <img alt="loader" className={classes.loaderImage} src={loader} />
        <Flex
          alignItems="center"
          className={classes.spinnerContainer}
          style={{ width: containerWidth }}
        >
          <FontAwesomeIcon
            style={{ fontSize: 20, marginRight: 16 }}
            icon={regular('circle-notch')}
            color={theme.token.colorPrimary}
            spin
          />
          <BaseText color={theme.token.colorTextDescription} className="space-nowrap" ref={textRef}>
            {LOADING_PHRASES[loadingPhraseIndex]}...
          </BaseText>
        </Flex>
      </Flex>
    </Flex>
  );
};
