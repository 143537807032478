import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export enum TimeFilterValue {
  TWO_HOURS = '2h',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type AggregationMinutes = 5 | 60 | 1440;
export type AggregationStep = '5m' | '1h' | '1d';

const TIME_FILTERS = [
  {
    value: TimeFilterValue.TWO_HOURS,
    label: 'Past 2 hours',
  },
  {
    value: TimeFilterValue.DAY,
    label: 'Past 1 day',
  },
  {
    value: TimeFilterValue.WEEK,
    label: 'Past 7 days',
  },
  {
    value: TimeFilterValue.MONTH,
    label: 'Past 30 days',
  },
];

export const getTimeFilterOptions = (showTwoHourView: boolean) =>
  TIME_FILTERS.filter(({ value }) => value !== TimeFilterValue.TWO_HOURS || showTwoHourView);

const getFiveMinuteStartTwoHoursAgo = () => {
  const minuteStart = dayjs.utc().subtract(2, 'hours').startOf('minute');
  const minutes = minuteStart.minute();
  const nearestMultipleOfFive = Math.floor(minutes / 5) * 5;
  return minuteStart.minute(nearestMultipleOfFive).toISOString();
};

export const getTimeParams = (timeFilter: TimeFilterValue) => {
  let start;
  let aggregationMinutes: AggregationMinutes;
  let step: AggregationStep;

  switch (timeFilter) {
    case TimeFilterValue.TWO_HOURS:
      start = getFiveMinuteStartTwoHoursAgo();
      aggregationMinutes = 5;
      step = '5m';
      break;
    case TimeFilterValue.DAY:
      start = dayjs.utc().subtract(1, 'days').startOf('hour').toISOString();
      aggregationMinutes = 60;
      step = '1h';
      break;
    case TimeFilterValue.WEEK:
      start = dayjs.utc().subtract(7, 'days').startOf('day').toISOString();
      aggregationMinutes = 60;
      step = '1h';
      break;
    case TimeFilterValue.MONTH:
      start = dayjs.utc().subtract(30, 'days').startOf('day').toISOString();
      aggregationMinutes = 1440; // 60 * 24
      step = '1d';
  }

  return { start, aggregationMinutes, step };
};

export const formatGraphLabel = (timeFilter: TimeFilterValue) => {
  if (timeFilter === TimeFilterValue.DAY || timeFilter === TimeFilterValue.TWO_HOURS) {
    return 'HH:mm';
  }
  if (timeFilter === TimeFilterValue.WEEK) {
    return 'MMM D, HH:mm';
  }
  return 'MMM D';
};
