import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PropertyType, TypeOption, UnionType } from './types';
import theme from '@css/theme';

// @todo use ditto
export enum DATA_CATALOG_TABS {
  EVENTS = 'Events',
  PROPERTIES = 'Properties',
}

export const DATA_CATALOG_API_PREFIX = '/catalog';

export const API_URLS = {
  events: `${DATA_CATALOG_API_PREFIX}/events`,
  allEvents: `${DATA_CATALOG_API_PREFIX}/events/all`,
  eventMetrics: (event: { name: string; eventType: string }) =>
    `/events?eventName=${event.name}&eventType=${event.eventType}`,
  singleEvent: (id: string) => `${DATA_CATALOG_API_PREFIX}/events/${id}`,
  properties: `${DATA_CATALOG_API_PREFIX}/properties`,
  allProperties: `${DATA_CATALOG_API_PREFIX}/properties/all`,
  singleProperty: (id: string) => `${DATA_CATALOG_API_PREFIX}/properties/${id}`,
  categories: `${DATA_CATALOG_API_PREFIX}/categories`,
  singleCategory: (id: string) => `${DATA_CATALOG_API_PREFIX}/categories/${id}`,
  customTypes: `${DATA_CATALOG_API_PREFIX}/custom-types`,
  singleCustomType: (id: string) => `${API_URLS.customTypes}/${id}`,
};

export const EVENT_TYPE_OPTIONS = [
  { label: 'Track', value: 'track' },
  { label: 'Identify', value: 'identify' },
  { label: 'Page', value: 'page' },
  { label: 'Screen', value: 'screen' },
  { label: 'Group', value: 'group' },
];

export const categoriesToIcons = {
  conversion: solid('money-bill-transfer'),
  marketing: solid('megaphone'),
  onboarding: solid('handshake'),
  general: solid('tag'),
};

export const EVENT_TYPE_TAG_COLORS = {
  track: {
    color: theme.token.colorPrimaryText,
    backgroundColor: theme.token['blue.2'],
  },
  identify: {
    color: theme.token['magenta.8'],
    backgroundColor: theme.token['magenta.3'],
  },
  page: {
    color: theme.token['teal.8'],
    backgroundColor: theme.token['teal.2'],
  },
  screen: {
    color: theme.token['gray.8'],
    backgroundColor: theme.token['gray.4'],
  },
  group: {
    color: theme.token['gold.8'],
    backgroundColor: theme.token['gold.4'],
  },
};

export const EDITOR_ERROR_MESSAGES = {
  requiredField: 'This field is required.',
  restrictedName: (name: string) => `You can't use "${name}" as a name.`,
  invalidString: (entity: string) =>
    `${entity} must be between 2 and 65 characters long, start with a letter and contain only letters, numbers, underscores, commas, spaces, dashes and dots.`,
  invalidPropertyEventName: 'This field must be between 1 and 65 characters long.',
  nameExists: 'Name must be unique.',
  invalidDescription:
    'Description must be between 3 and 2000 characters long and start with a letter.',
  typeExists: (name: string, type: string) =>
    `There is already a property with ${name} name and ${type} type.`,
  typeArrayExists: (name: string, type: string, itemsType: string) =>
    `There is already a property with ${name} name, ${type} type and items type of ${itemsType}.`,
  trackEventCannotBeChanged: 'Track event type cannot be changed.',
  notEmpty: (entity: string) => `${entity} should not be empty.`,
  invalidCustomTypeName:
    'Custom type name must be between 2 and 65 characters long, start with a capital letter and contain only letters, numbers, underscores and dashes.',
};

export const PROPERTY_EVENT_NAME_REGEX = /^.{1,64}$/;

export const CUSTOM_TYPE_NAME_REGEX = /^[A-Z][\w-]{1,64}$/;

export const STRING_REGEX = /^[A-Z_a-z][\s\w,.-]{1,64}$/;

export const DESCRIPTION_REGEX = /^[A-Z_a-z].{2,1998}$/;

export const PROPERTY_TYPES: TypeOption[] = [
  { label: 'Object', value: 'object', icon: solid('brackets-curly'), arrayLabel: 'Object array' },
  { label: 'String', value: 'string', icon: solid('text-size'), arrayLabel: 'String array' },
  { label: 'Number', value: 'number', icon: solid('hashtag'), arrayLabel: 'Number array' },
  { label: 'Integer', value: 'integer', icon: solid('square-5'), arrayLabel: 'Integer array' },
  { label: 'Array', value: 'array', icon: solid('brackets-square'), arrayLabel: 'Array array' },
  {
    label: 'Boolean',
    value: 'boolean',
    icon: solid('object-subtract'),
    arrayLabel: 'Boolean array',
  },
  { label: 'Null', value: 'null', icon: solid('battery-empty'), arrayLabel: 'Null array' },
];

export enum DATA_CATALOG_QUERY_PARAMS {
  ID = 'id',
}

export const UNION_TYPES: (Omit<TypeOption, 'value' | 'arrayLabel'> & { value: string })[] = [
  { label: 'One of', value: 'oneOf', icon: solid('list-radio') },
  { label: 'Any of', value: 'anyOf', icon: solid('list-check') },
  { label: 'All of', value: 'allOf', icon: solid('list-check') },
];

// used to define and control what types can used for creating complex custom types
const complexTypes = ['object', 'array'];

// used to define and control what types can be used for creating simple custom types
const simpleTypes = ['string', 'number', 'boolean', 'null', 'integer'];

// used to define and control what types can be used as items in custom type of array
export const customArrayItemsTypes = [...simpleTypes, ...complexTypes];

export const DEFINITION_COMPLEX_TYPES: (Omit<TypeOption, 'value' | 'arrayLabel'> & {
  value: string;
})[] = PROPERTY_TYPES.filter((type) => complexTypes.includes(type.value));

export const DEFINITION_GENERIC_TYPES: (Omit<TypeOption, 'value' | 'arrayLabel'> & {
  value: string;
})[] = PROPERTY_TYPES.filter((type) => simpleTypes.includes(type.value));

export const DEFINITION_TYPES = [
  ...DEFINITION_GENERIC_TYPES,
  ...UNION_TYPES,
  ...DEFINITION_COMPLEX_TYPES,
];

export const genericTypes: PropertyType[] = [
  'string',
  'number',
  'boolean',
  'object',
  'array',
  'null',
  'integer',
];

export const unionTypes = ['oneOf', 'anyOf', 'allOf'];

export const unionTypeNotes: { [key in UnionType]: string } = {
  oneOf: 'The given data must be valid against exactly one of the given custom types.',
  anyOf: 'The given data must be valid against any (one or more) of the given custom types.',
  allOf:
    'The given data must be valid against all of the given custom types. Custom types must be of the same type.',
};
