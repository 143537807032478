import React, { ReactNode } from 'react';
import classes from './source-settings-row.module.scss';
import { Subtext } from '@ui-library/typography';
import theme from '@css/theme';

interface OwnProps {
  cta?: ReactNode;
  name: string | ReactNode;
  description?: string | ReactNode;
}

type Props = OwnProps;

const SourceSettingsRow = (props: Props) => (
  <div className={classes.settings_row_container}>
    <div className={classes.settings_info}>
      <Subtext>{props.name}</Subtext>
      <Subtext
        color={theme.token.colorTextDescription}
        className={classes.settings_description}
        data-testid="description"
      >
        {props.description}
      </Subtext>
    </div>
    {props.cta}
  </div>
);

/* eslint-disable import/no-default-export */
export default SourceSettingsRow;
