import { Region } from '@components/common/constants';
import { Analytics } from '@lib/analytics';
import { bugsnagSetUser } from '@lib/bugsnag';
import { experiment } from '@lib/experiment';
import { apiAuthCaller } from '@services/apiCaller';
import auth, { CognitoTokens } from '@services/cognito';
import { IRootStore } from '@stores/index';
import { loadStores } from '@stores/util';
import { WorkspaceEnvironment } from '@stores/workspace';
import { WorkspaceStatus } from '@stores/workspace/constants';
import LocalStorage from '@utils/localStorage';
import { CatchErr } from '@utils/types';
import { action, autorun, computed, makeObservable, observable, toJS } from 'mobx';
import { OrganizationRole, UserRoles, WorkspaceRole } from '../member';

export enum LoginMfaState {
  None = '',
  ForcedSetup = 'mfa_setup',
}

export enum PendingTask {
  databricks = 'DATABRICKS_ONBOARDING',
}

export type Provider = 'Google' | '';

type MFAStatus = 'SMS' | 'NOMFA';

interface IMfaData {
  sessionToken?: string;
  status?: string;
  email?: string;
  provider?: string;
  providerUserId?: string;
  state?: LoginMfaState;
}

interface IUserData {
  email: string;
  id: string;
  name: string;
  provider: Provider;
  providerUserId: string;
  workspaces: IWorkspace[];
  phoneNumber: string;
  mfaStatus: MFAStatus;
  enforcedMfa: boolean;
  canSetupMfa: boolean;
  organizations: IOrganization[];
  pendingTasks: PendingTask[];
}

interface IOrganization {
  id: string;
  name: string;
  role: OrganizationRole;
  planType: string;
}

export type Traits =
  | {
      userCompanyRole: string[] | undefined;
      onboardingQuestionsStatus: 'completed' | undefined;
      questionProductInterest: string[] | undefined;
      questionMigratingFrom: string | undefined;
      questionHowDidYouHear: string | undefined;
    }
  | {
      onboardingQuestionsStatus: 'skipped';
    };

interface IWorkspace {
  id: string;
  name: string;
  organizationId: string;
  environment: WorkspaceEnvironment;
  roles: WorkspaceRole[];
  defaultRegion: Region;
  status: WorkspaceStatus;
}

export interface IUserStore {
  setUserSessionForMFA(email: string, mfaData: IMfaData): void;
  setAndRefreshToken(token: string): Promise<void>;
  id: string;
  name: string;
  email: string;
  token: string;
  loggedIn: boolean;
  invitedUserFirstTimeLogin: boolean;
  firstTimeLogin: boolean;
  sessionData: CognitoTokens;
  workspaces: Array<IWorkspace>;
  selectedWorkspaceId: string;
  selectedOrganizationId: string;
  isOrgAdmin: boolean;
  roles: UserRoles;
  rootStore: IRootStore;
  provider: Provider;
  providerUserId: string;
  mfaData: IMfaData | undefined;
  phoneNumber: string;
  mfaStatus: MFAStatus;
  enforcedMfa: boolean;
  canSetupMfa: boolean;
  signUp(name: string, organization: string, email?: string): void;
  setName(name: string): void;
  loginUser(sessionData: CognitoTokens): Promise<void>;
  trackOnboarding(traits: Traits): void;
  setLoginMetadata(metadata: { invited?: boolean; firstTimeLogin?: boolean }): void;
  setProvider(provider: Provider, providerUserId: string): void;
  signOutUser(signOutfromBackend?: boolean, globalSignout?: boolean): void;
  switchWorkspace(workspaceId: string): Promise<void>;
  updateEnforcedMfa(): Promise<void>;
  setPhoneNumber(number: string): void;
  setMfaStatus(value: MFAStatus): void;
  getUser(): Promise<void>;
  isSso: boolean;
  isGoogleOrSso: boolean;
  organizations: IOrganization[];
  pendingTasks: PendingTask[];
  showDatabricksWorkspace: boolean;
  setShowDatabricksWorkspace(val: boolean): void;
  removePendingTask(task: PendingTask): void;
  selectedWorkspaceRegion: Region;
  selectedWorkspace: IWorkspace | undefined;
  selectedOrganization: IOrganization | undefined;
}

type LocalUserStore = Pick<UserStore, 'token' | 'loggedIn' | 'email' | 'selectedWorkspaceId'> & {
  refreshToken: UserStore['sessionData']['refreshToken'];
};

function autoSave(store: UserStore) {
  let firstRun = true;
  autorun(() => {
    const userStore = toJS(store);
    if (!firstRun) {
      const json: LocalUserStore = {
        refreshToken: userStore.sessionData.refreshToken,
        loggedIn: userStore.loggedIn,
        token: userStore.token,
        email: userStore.email,
        // workspaceId is required in destination oauth callback route to make API call
        selectedWorkspaceId: userStore.selectedWorkspaceId,
      };
      LocalStorage.set('userStore', JSON.stringify(json));
    }
    firstRun = false;
  });
}

export class UserStore implements IUserStore {
  @observable public id = '';

  @observable public name = '';

  @observable public email = '';

  @observable public token = '';

  @observable public sessionData: CognitoTokens = {
    idToken: '',
    refreshToken: '',
  };

  @observable public loggedIn = false;

  @observable public invitedUserFirstTimeLogin = false;

  @observable public firstTimeLogin = false;

  @observable enforcedMfa = false;

  @observable public workspaces: IWorkspace[] = [];

  @observable public organizations: IOrganization[] = [];

  @observable public selectedWorkspaceId = '';

  @observable public rootStore: IRootStore;

  @observable public provider: Provider = '';

  @observable public providerUserId = '';

  @observable public mfaData: IMfaData | undefined;

  @observable phoneNumber = '';

  @observable mfaStatus: MFAStatus = 'NOMFA';

  @observable canSetupMfa = false;

  @observable pendingTasks: PendingTask[] = [];

  @observable showDatabricksWorkspace = true;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.load();
    autoSave(this);
    this.rootStore = rootStore;

    // refreshing the stores
    setInterval(
      async () => {
        await this.getAllData();
      },
      15 * 60 * 1000,
    );
  }

  public load() {
    const userStore = LocalStorage.get('userStore');
    if (userStore) {
      const store: LocalUserStore = JSON.parse(userStore);
      this.sessionData = { refreshToken: store.refreshToken, idToken: store.token };
      this.token = store.token;
      this.loggedIn = store.loggedIn;
      this.email = store.email;
      this.selectedWorkspaceId = store.selectedWorkspaceId;
    }
  }

  @action.bound
  public setName(name: string): void {
    this.name = name;
  }

  @action.bound
  public setProvider(provider: Provider, providerUserId: string): void {
    this.provider = provider;
    this.providerUserId = providerUserId;
  }

  @action.bound
  public setPhoneNumber(number: string): void {
    this.phoneNumber = number;
  }

  @action.bound
  public setShowDatabricksWorkspace(val: boolean): void {
    this.showDatabricksWorkspace = val;
  }

  @action.bound
  public setMfaStatus(value: MFAStatus): void {
    this.mfaStatus = value;
  }

  @action.bound
  public signUp(name: string, organization: string, email?: string) {
    this.loggedIn = false;
    this.name = name;
    this.rootStore.organizationStore.name = organization;
    if (email) {
      this.email = email;
    }
  }

  @action.bound async setAndRefreshToken(idToken: string) {
    if (idToken !== this.token) {
      this.token = idToken;
    }
    let shouldSignOut = false;
    // Gets current session. Tokens are refreshed by amplify automatically when they expire
    try {
      const res = await auth.currentSession(this.sessionData, this.email);

      if (res.status === 'success' && res.idToken) {
        this.token = res.idToken;
        this.sessionData.idToken = res.idToken;
        this.sessionData.refreshToken = res.refreshToken;
      }
    } catch (error: CatchErr) {
      if (error && error.response && error.response.status === 400) {
        shouldSignOut = true;
      }
    }

    // doing the same thing as error response interceptor
    if (shouldSignOut) {
      await this.signOutUser();
    }
  }

  @action.bound
  public setUserSessionForMFA(email: string, mfaData: IMfaData) {
    this.email = email;
    this.mfaData = mfaData;
    this.sessionData.idToken = mfaData.sessionToken as string;
  }

  @action.bound
  async updateEnforcedMfa() {
    const userData: { data: IUserData } = await apiAuthCaller({
      nonWorkspaceRoute: true,
      region: Region.US,
    }).get('/getUser');
    const { enforcedMfa } = userData.data;
    this.enforcedMfa = enforcedMfa;
  }

  @action.bound
  async loginUser(sessionData: CognitoTokens) {
    this.setSessionToken(sessionData);
    await this.getUser();
  }

  public async getUser() {
    try {
      const userData = await apiAuthCaller({
        nonWorkspaceRoute: true,
        region: Region.US,
      }).get<IUserData>('/getUser');
      const {
        id,
        name,
        email,
        phoneNumber,
        mfaStatus,
        enforcedMfa,
        workspaces,
        canSetupMfa,
        provider,
        providerUserId,
        organizations,
        pendingTasks = [],
      } = userData.data;
      this.id = id;
      this.email = email;
      this.name = name;
      this.phoneNumber = phoneNumber;
      this.mfaStatus = mfaStatus;
      this.enforcedMfa = enforcedMfa;
      this.canSetupMfa = canSetupMfa;
      this.pendingTasks = pendingTasks;
      this.organizations = organizations;
      this.workspaces = workspaces;
      this.selectedWorkspaceId = this.getInitialWorkspaceId();
      this.saveCurrentWorkspace();
      this.mfaData = {};
      this.loggedIn = true;
      this.setProvider(provider, providerUserId);

      const {
        selectedOrganizationId,
        selectedWorkspaceId,
        selectedWorkspace,
        selectedOrganization,
      } = this;
      await experiment.start({
        id: this.id,
        workspaceId: selectedWorkspaceId,
        organizationId: selectedOrganizationId,
        planType: selectedOrganization?.planType,
        environment: selectedWorkspace?.environment,
      });
      Analytics.identify(this.id, {
        email: this.email,
        workspacesId: selectedWorkspaceId,
        organizationId: selectedOrganizationId || '',
      });
      bugsnagSetUser(this);
    } catch (err) {}
  }

  private setSessionToken(session: CognitoTokens) {
    this.sessionData = session;
    this.token = this.sessionData.idToken;
  }

  trackOnboarding(traits: Traits) {
    Analytics.identify(this.id, { email: this.email, ...traits });
    Analytics.track({ event: 'userCompletedOnboarding', properties: traits });
  }

  setLoginMetadata({
    invited = false,
    firstTimeLogin = false,
  }: {
    invited?: boolean;
    firstTimeLogin?: boolean;
  }) {
    this.invitedUserFirstTimeLogin = firstTimeLogin && !!invited;
    this.firstTimeLogin = firstTimeLogin;

    if (this.firstTimeLogin) {
      Analytics.identify(this.id, {
        email: this.email,
        workspacesId: this.selectedWorkspaceId,
        organizationId: this.rootStore.organizationStore.id,
      });
      Analytics.track({ event: 'ads_cnv_app_signup', properties: {} });
    }
  }

  private getInitialWorkspaceId() {
    const accessibleWorkspaces = this.workspaces.filter((workspace) => {
      const organization = this.organizations.find((org) => org.id === workspace.organizationId)!;
      return (
        workspace.status === WorkspaceStatus.ACTIVE &&
        (organization.role === OrganizationRole.admin || workspace.roles.length > 0)
      );
    });
    if (accessibleWorkspaces.length === 0) {
      return '';
    }
    const workspaceId = LocalStorage.get('lastVisitedWorkspace');
    if (workspaceId) {
      const workspaceExists = accessibleWorkspaces.some((x: IWorkspace) => x.id === workspaceId);
      if (workspaceExists) {
        return workspaceId;
      }
    }
    return accessibleWorkspaces[accessibleWorkspaces.length - 1].id;
  }

  removePendingTask(task: PendingTask) {
    const tasks = this.pendingTasks;
    this.pendingTasks = tasks.filter((t) => t !== task);
  }

  private async signOutFromBackend(globalSignout = false): Promise<void> {
    try {
      await apiAuthCaller({
        idToken: this.sessionData.idToken,
        nonWorkspaceRoute: true,
        region: Region.US,
      })
        .ignore400()
        .post(
          '/signout',
          { refreshToken: this.sessionData.refreshToken },
          { params: { globalSignout } },
        );
    } catch (err) {}

    if (globalSignout) {
      await this.setAndRefreshToken(this.sessionData.idToken);
    }
  }

  @action.bound
  public async signOutUser(signOutfromBackend = false, globalSignout = false) {
    if (signOutfromBackend) {
      await this.signOutFromBackend(globalSignout);
    }

    this.loggedIn = false;
    this.id = '';
    this.name = '';
    this.email = '';
    this.token = '';
    this.sessionData = {
      refreshToken: '',
      idToken: '',
    };
    this.provider = '';
    this.providerUserId = '';
    this.workspaces = [];
    this.selectedWorkspaceId = '';

    this.resetStores();
    experiment.reset();
    bugsnagSetUser(this);
    try {
      await import('@aws-amplify/auth').then((res) => res.Auth.signOut());
    } catch (err) {}
  }

  private saveCurrentWorkspace() {
    LocalStorage.set('lastVisitedWorkspace', this.selectedWorkspaceId);
  }

  @action.bound
  public async switchWorkspace(workspaceId: string) {
    const { userStore } = this.rootStore;

    this.resetStores();
    this.selectedWorkspaceId = workspaceId;
    this.saveCurrentWorkspace();

    await experiment.fetch({
      id: userStore.id,
      workspaceId: this.selectedWorkspaceId,
      organizationId: this.selectedOrganizationId,
      planType: this.selectedOrganization?.planType,
      environment: this.selectedWorkspace?.environment,
    });
  }

  @action.bound
  public async getAllData() {
    // check if user is logged in before doing any request
    if (this.loggedIn && !document.hidden) {
      await loadStores();
    }
  }

  @computed get selectedOrganizationId() {
    const workspace = this.workspaces.find(
      (workspace) => workspace.id === this.selectedWorkspaceId,
    );
    return workspace?.organizationId || '';
  }

  @computed get isOrgAdmin() {
    return this.roles?.organization === OrganizationRole.admin;
  }

  @computed get roles() {
    const member = this.rootStore.organizationStore.members.find(
      (member) => member.email === this.email,
    );
    return member?.roles!;
  }

  @computed get isSso() {
    return !!this.providerUserId && this.provider !== 'Google';
  }

  @computed get isGoogleOrSso() {
    if (this.isSso) {
      return true;
    }

    // Handle Google users logging in with password
    let decodedPayload = {} as Record<string, string>;
    try {
      const jwtPayload = this.token.split('.')[1] || '';
      decodedPayload = JSON.parse(atob(jwtPayload) || '{}');
    } catch (error) {
      return false;
    }

    return decodedPayload['cognito:username']?.startsWith('Google');
  }

  @computed get selectedWorkspaceRegion(): Region {
    const workspace = this.workspaces.find(
      (workspace) => workspace.id === this.selectedWorkspaceId,
    );
    return workspace?.defaultRegion || Region.US;
  }

  @computed get selectedWorkspace() {
    return this.workspaces.find((workspace) => workspace.id === this.selectedWorkspaceId);
  }

  @computed get selectedOrganization() {
    return this.organizations.find(
      (organization) => organization.id === this.selectedOrganizationId,
    );
  }

  @action.bound
  private resetStores() {
    const {
      billingStore,
      sourcesListStore,
      destinationsListStore,
      modelListStore,
      audienceListStore,
      workspaceStore,
      organizationStore,
      featuresStore,
      settingsStore,
      retlSourceListStore,
      retlConnectionListStore,
      credentialStore,
      serviceAccessTokenStore,
    } = this.rootStore;

    workspaceStore.reset();
    organizationStore.reset();
    settingsStore.reset();
    billingStore.clearBillingInfo();
    featuresStore.clearFeatures();

    sourcesListStore.reset();
    destinationsListStore.reset();
    modelListStore.reset();
    audienceListStore.reset();
    retlSourceListStore.reset();
    retlConnectionListStore.reset();
    credentialStore.reset();
    serviceAccessTokenStore.reset();
    featuresStore.firstLoad = false;
  }
}
